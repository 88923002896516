<template>
    <transition  name="slide-fade">
        <div v-if="show_chat" class="comment-wrap">
            <div style="border-bottom: 2px solid white;">
                <p class="is-size-5 has-text-info-dark">
                    {{ $t('project_comments') }}
                        {{$parent.$parent.event_details.Number}}
                    <!-- - {{ $t('guest_comments') }} {{socket_clients.clients}} -->
                    <button @click.prevent="close_chat" class="delete is-pulled-right"></button>
                </p>
                <div class="tabs is-fullwidth is-toggle pt-2">
                    <ul class="chat_tab_wrap">
                        <li :class="[is_chat ? 'is-active ' : '']">
                            <a @click.prevent="is_chat = true">
                                <!-- <span class="icon"><i class="fas fa-angle-left" aria-hidden="true"></i></span> -->
                                <span>Chat</span>
                            </a>
                        </li>
                        <li :class="[!is_chat ? 'is-active ' : '']">
                            <a @click.prevent="is_chat = false">  
                                <span>Notes</span>
                                <!-- <span class="icon"><i class="fas fa-angle-right" aria-hidden="true"></i></span> -->
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <!------------------------------------Chat----------------------------->
            <div v-if="is_chat">
                <div class="mr-2 mb-1">
                    <select v-model="comment_priority" class="is-borderl" name="" id="">
                        <option value="0">{{$t("normal")}}</option>
                        <option value="1">{{$t("medium")}}</option>
                        <option value="2">{{$t("high")}}</option>
                    </select>
                </div>
                <div class="columns">
                    <div class="column is-12">
                        <div class="columns">
                            <div class="column is-10">
                                <div class="field">
                                    <div class="control">
                                        <textarea v-model="new_comment.text" class="textarea is-small is-info add-materials-table-input" rows="2" type="text" placeholder="Write a comment"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-2 pl-0">
                                <div class="column p-0">
                                    <div @click.prevent="create_comment" class="send-icon flex-center-center is-clickable" :style="[ !new_comment.text ? {'opacity':'0.3'} : {'opacity': '1'}]">
                                    <!-- <div @click.prevent="create_comment" class="send-icon flex-center-center is-clickable" > -->
                                        <svg class="icon line" width="96" height="96" id="send" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M5.44,4.15l14.65,7a1,1,0,0,1,0,1.8l-14.65,7A1,1,0,0,1,4.1,18.54l2.72-6.13a1.06,1.06,0,0,0,0-.82L4.1,5.46A1,1,0,0,1,5.44,4.15Z" style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px;"></path>
                                        <line x1="7" y1="12" x2="11" y2="12" style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px;"></line>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="comments-list">
                            <div v-for="comment in comments_list" :key="comment.guid" 
                                :class="[comment.created_by == user_id ? 'is-pulled-right' : '']"
                                    class="comment mb-3 ">
                                <p class="comment-text has-text-dark has-text-center has-background-white" 
                                    :style="[ comment.created_by == user_id ? {'display':'table', 'margin-left':'auto'} : {'display':'table', 'margin-right':'auto'}]" > 
                                    <span>
                                        <i class="fa fa-comment normal_priority" v-if="comment.priority == 0" :title="$t('normal')"></i>
                                        <i class="fa fa-comment medium_priority" v-else-if="comment.priority == 1" :title="$t('medium')"></i>
                                        <i class="fa fa-comment high_priority" v-else-if="comment.priority == 2" :title="$t('high')"></i>
                                    </span>
                                    {{comment.text}} 
                                    <span v-if="comment.can_delete < 20 && comment.created_by == user_id " 
                                        @click.prevent="delete_comment(comment.guid)"
                                        class="is-pulled-right has-text-danger ml-1 is-clickable">
                                        <i class="fas fa-trash-alt"></i>
                                    </span>
                                    <span class="is-size-7 has-text-grey-light is-pulled-right ml-2 mt-1">
                                        {{comment.created_by_name}} - {{comment.created_at | datetime}}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-------------------------------------Notes------------------------>
            <div v-else>
                <div class="mr-2 mb-1">
                    <select v-model="$parent.$parent.note_priority" class="is-borderl" name="" id="">
                        <option value="0">{{$t("normal")}}</option>
                        <option value="1">{{$t("medium")}}</option>
                        <option value="2">{{$t("high")}}</option>
                    </select>
                </div>
                <div class="columns">
                    <div class="column is-12">
                        <div class="columns">
                            <div class="column is-10">
                                <div class="field">
                                    <div class="control">
                                        <textarea v-model="$parent.$parent.project_note" class="textarea is-small is-info add-materials-table-input" rows="2" type="text" placeholder="Leave a note..."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-2 pl-0">
                                <div class="column p-0">
                                    <div @click.prevent="$parent.$parent.createProjectNote()" class="send-icon flex-center-center is-clickable" :style="[ !$parent.$parent.project_note ? {'opacity':'0.3'} : {'opacity': '1'}]">
                                        <svg class="icon line" width="96" height="96" id="send" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M5.44,4.15l14.65,7a1,1,0,0,1,0,1.8l-14.65,7A1,1,0,0,1,4.1,18.54l2.72-6.13a1.06,1.06,0,0,0,0-.82L4.1,5.46A1,1,0,0,1,5.44,4.15Z" style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px;"></path>
                                        <line x1="7" y1="12" x2="11" y2="12" style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px;"></line>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="comments-list">
                            <div v-for="(note, index) in $parent.$parent.notes" :key="index" 
                                :class="[note.created_by == user_id ? 'is-pulled-right' : '']"
                                    class="comment mb-3 ">
                                <p class="comment-text has-text-dark has-text-center has-background-white" 
                                    :style="[ note.created_by == user_id ? {'display':'table', 'margin-left':'auto'} : {'display':'table', 'margin-right':'auto'}]" > 
                                    <span>
                                        <i class="fa fa-comment normal_priority" v-if="note.note_type == 0" :title="$t('normal')"></i>
                                        <i class="fa fa-comment medium_priority" v-else-if="note.note_type == 1" :title="$t('medium')"></i>
                                        <i class="fa fa-comment high_priority" v-else-if="note.note_type == 2" :title="$t('high')"></i>
                                    </span>
                                    {{note.content}}
                                    <span v-if="note.can_delete < 20 && note.created_by == user_id " 
                                        @click.prevent="$parent.$parent.delete_note(note.guid)"
                                        class="is-pulled-right has-text-danger ml-1 is-clickable">
                                        <i class="fas fa-trash-alt"></i>
                                    </span>
                                    <span class="is-size-7 has-text-grey-light is-pulled-right ml-2 mt-1">
                                        {{note.created_by_name}} - {{note.created_at | datetime}} 
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </transition >
</template>

<script>
import axios from 'axios'
import socketMixin from '@/socket-mixin.js';
import socket from '@/socket.js'
export default {
    props:['show_chat'],
    mixins: [ socketMixin ],
    data() {
        return {
            new_comment:{},
            save_message:true,
            delete_message:true,
            comment_priority: 0,
            is_chat: true,
        }
    },
    created(){
        this.get_comments()
        //get project notes
        this.$parent.$parent.getProjectNotes()
        this.namespace = this.project_id
	   	socket.on('new message', (data) => {
            this.alert_toaster(data,0)
            this.new_comment = {}
            this.comments_list.unshift(data)
            this.$emit('comment_count', this.comments_list.length)
        
        })
         
        socket.on('delete message', (data) => {
            setTimeout(()=>{
				this.alert_toaster(data,this.comments_list)
			},100)
			setTimeout(()=>{
				this.comments_list = this.comments_list.filter(d => d.guid != data )
				this.$emit('comment_count', this.comments_list.length)      
			},200)
        })
        // this.socket_connect()
    },
    methods: {
        get_comments(){
            axios.post(this.$tetris_server + '/get/proj/comments', { project_id: this.project_id })
            .then(c => {
                this.comments_list = c.data[0]
                if(this.comments_list.length > 0){
                    this.$emit('comment_count', this.comments_list.length)
                }
				this.$parent.$parent.setTetrisRequestArray(c.data[1])
            })
        },
        create_comment(){
            if(this.new_comment.text){
                var v = {
                    text: this.new_comment.text,
                    priority: this.comment_priority,
                    created_by: this.user_id,
                    project_id: this.project_id, 
                    created_by_name: this.user_name
                }
                    this.$toasted.show( this.$t('is_creating_message') , {
                    position: 'bottom-right',
                    duration: 3500,
                    keepOnHover: true,
                    type: 'info',
                    theme: 'outline',
                    icon: {
                        name: 'undo',
                    },
                    action : [ 
                        {   text : 'Undo',
                            class: 'has-text-black',
                            onClick : (e, toastObject) => {
                                this.save_message = false
                                toastObject.goAway(0);
                            }
                        }]
                    })
                setTimeout(()=>{
                if(this.save_message){
                axios.post(this.$tetris_server + '/create/comment', v)
                .then(r => {
                    if(r.data == 'chat_empty_toaster'){
                        this.$toast('Please write a comment before submitting', 'danger')
                    }
                    else{
                        this.comments_list.unshift(r.data[0])
                        this.send_new_message(r.data[0])
                    }
                    })
                }
                    this.save_message = true 
                },3501)
                //    

            }
        },
        delete_comment(guid){
            this.$fire({
                text: this.$t('sure_delete_comment'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
                width: 300,
                customClass: {
                    confirmButton: 'button is-success is-small',
                    cancelButton: 'button is-small',
                },
            }).then(response => {
                if(response.value){
                    this.$toasted.show(this.$t('comment_deleting') , {
                           position: 'bottom-right',
                           duration: 3500,
                           keepOnHover: true,
                           type: 'info',
                           theme: 'outline',
                           icon: {
                               name: 'undo',
                           },
                           action : [ 
                               {   text : 'Undo',
                                   class: 'has-text-black',
                                   onClick : (e, toastObject) => {
                                       this.delete_message = false
                                       toastObject.goAway(0);
                                   }
                               }]
                           })
                       setTimeout(()=>{
                           if(this.delete_message){
                           axios.post(this.$tetris_server + '/delete/comment', {guid: guid})
                           .then(c => {
                               this.delete_new_message(c.data)   
                               this.comments_list = this.comments_list.filter(d => d.guid != guid )           
                               })
                           }
                               this.delete_message = true
                           },3501)
                }
            })
        },
        close_chat(){
            this.$emit('close_chat')
        },
        cancelcallback(){}

    },

    computed: {
        user_id(){
            var o = ''
            if(JSON.parse(localStorage.getItem("user"))){
                o = JSON.parse(localStorage.getItem("user")).IdUser
            }
            return o
        },
        user_name(){
            var o = ''
            if(JSON.parse(localStorage.getItem("user"))){
                o = JSON.parse(localStorage.getItem("user")).UserDisplayName
            }
            return o
        },
        project_id(){
            var url = this.$route.path
            var proj_id = url.split('/')[2]

            return proj_id

        }
    },


}
</script>

<style >
    .comment {
        float:left;
        display:block;
        width: 65%;
        clear: both;
    }
    .comment-text {
        /* display: block; */
        border:1px solid #d8d8d8;
        border-radius: 17px;
        font-size:14px;
        padding:3px 16px 5px;
        box-shadow: 0px 8px 3px -3px #c5c6c2;
    }
    .comment-info-text{
        font-size:10px;
        margin-left:15px;
    }
    .send-icon{
        height:50px;
        width:50px;
        border-radius: 25px;
        background: #e37032;
    }
    .comment-wrap{
        min-width: 450px;
        padding:8px;
        background:rgba(245, 246, 247, 0.97);
        position: absolute;
        right:18px;
        z-index: 999;
        box-shadow: 0 0 4px 1px #00000029;
        overflow-y: scroll; 
        overflow-x: hidden;
        height:auto;
        border-radius:10px;
    }

    .comment-wrap::-webkit-scrollbar{
        width:6px;
        height: 20cm;
        background:rgba(255, 166, 0, 0);
    }
    .comment-wrap::-webkit-scrollbar-thumb{
        background: #3e8ed0;
        border-radius:10px;
    }


    .slide-fade-enter-active {
    transition: all .3s ease;
    }
    .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
    }
    .is-borderl{
        border: 0;
        background: none;
    }
    .chat_tab_wrap li a{
        color:#000;
    }
</style>
